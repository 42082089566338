import React from "react";
import { Container, TabContent, TabPane } from "reactstrap";
// import Link from 'gatsby-link'
// import { graphql } from 'gatsby'
import Layout from "../components/layout";
import VANTA from "../vanta/vanta.net";

import Helmet from "react-helmet";

import styled from "styled-components";
import C from "../components/colors";

import eos from "../../static/images/eos.jpg";
import avalanche from "../../static/images/avalanche.jpg";
import aptos from "../../static/images/aptos.jpg";
import eosPixels from "../../static/images/pixels-banner.jpg";
import eosx from "../../static/images/eosx-banner.jpg";
import eosInfra from "../../static/images/infra-banner.jpg";
import eosGo from "../../static/images/eosgo-banner.jpg";
import waxImage from "../../static/images/wax_banner.jpg";
import logo from "../../static/images/logo-2.png";
import ogImage from "../../static/images/polar_ogimage.jpg";

const ogImageFinal = `https://www.polar.io${ogImage}`;

const Home = styled.div`
  @keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  // text-shadow: 0 1px ${C.bg},
  //     0 2px ${C.bg},
  //     1px 0px ${C.bg},
  //     -1px 0px ${C.bg},
  //     1px 1px ${C.bg},
  //     -1px 1px ${C.bg},
  //     1px -1px ${C.bg},
  //     -1px -1px ${C.bg},
  //     0 -1px ${C.bg};

  animation: fadein 1s ease-out;

  .team-section {
    padding-bottom: 2rem !important;
  }

  .team-description {
    :before {
      content: "————";
      display: block;
      font-size: 80%;
      opacity: 0.5;
      letter-spacing: -2px;
    }
  }

  .polar-logo {
    margin-right: 5px;
    width: 2.8rem;
  }

  .blue {
    color: ${C.blue};
  }
  .btn {
    display: inline-block;
    padding: 10px 20px;
    color: white;
    background: ${C.blue};
    font-weight: 900;
    text-transform: uppercase;
    letter-spacing: 2px;
    border-radius: 0;
    &:hover {
      background: ${C.blueLight};
    }
  }
  img {
    max-width: 100%;
    &.border {
      box-shadow: inset 0 0 1px rgba(0, 5, 20, 0.15);
    }
  }
  p {
    // background: ${C.bg};
    display: inline-block;
  }
  h2 {
    margin-bottom: 40px;
    font-size: 40px;
    font-weight: 600;
    .fal,
    .far {
      color: ${C.blue};
      margin-right: 5px;
      font-size: 2rem;
    }
    &:after {
      content: " ";
      width: 75px;
      height: 4px;
      background: ${C.blue};
      display: inline-block;
      margin-left: 5px;
    }
  }
  h3 {
    font-weight: 600;
    font-size: 22px;
    text-transform: uppercase;
    letter-spacing: 2px;
  }
  h5 {
    color: ${C.blue};
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: 14px;
  }
  section {
    padding: 80px 0;
    &.top {
      min-height: 60vh;
      display: flex;
      align-items: center;
      h1 {
        font-size: 100px;
        span {
          font-size: 133%;
          line-height: 0.75;
        }
      }
      @media (max-width: 720px) {
        min-height: 30vh;
        h1 {
          font-size: 60px;
        }
      }
    }
  }
  > .vanta {
    position: fixed;
    z-index: 1;
    top: 0;
    width: 100%;
    height: 100%;
  }
  > .container {
    position: relative;
    z-index: 10;
  }

  .team-member {
    @media (min-width: 720px) {
      display: flex;
      align-items: center;
    }
    margin-bottom: 40px;
    img {
      max-width: 13rem;
      border-radius: 0px;
      margin-right: 40px;
      margin-bottom: 20px;
    }
    .team-info {
      background: ${C.bg};
    }
  }

  .boxes {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .box {
    position: relative;
    display: inline-block;
    vertical-align: top;
    margin: 0;
    margin-bottom: 20px;
    border: 2px solid ${C.blue};
    padding: 30px 25px;
    background: white;
    &:before {
      content: " ";
      height: 16px;
      width: 16px;
      background: ${C.blue};
      position: absolute;
      top: 0px;
      z-index: 2;
      margin-left: -25px;
    }
    h5 {
      position: relative;
      overflow: hidden;
      margin: 5px 0 20px;
      span {
        background: white;
        position: relative;
        z-index: 1;
        padding-right: 10px;
      }
      &:after {
        content: " ";
        height: 2px;
        background: ${C.blue};
        position: absolute;
        top: 10px;
        width: 100%;
      }
    }
    &.fifth {
      width: calc(20% - 20px);
      @media (max-width: 720px) {
        margin-right: 10px;
        margin-left: 10px;
        width: calc(33.33% - 20px);
      }
    }
    &.third,
    &.half {
      width: calc(33.33% - 20px);
      @media (max-width: 720px) {
        width: 100%;
        display: block;
        margin-left: 0;
        margin-right: 0;
      }
    }
    &.half {
      width: calc(50% - 20px);
    }
    .item-title {
      font-weight: bold;
      margin-bottom: 5px;
    }
    p:last-child {
      margin-bottom: 0;
    }
  }

  .tab-flex {
    display: flex;
    @media (max-width: 720px) {
      display: block;
    }
    .tab-btns {
      width: 250px;
      min-width: 250px;
      position: relative;
      z-index: 1;
      .tab-btn {
        font-size: 21px;
        cursor: pointer;
        padding: 15px 20px;
        background: ${C.grayLight4};
        border: 2px solid ${C.gray};
        border-right: 2px solid ${C.blue};
        display: block;
        color: ${C.gray};
        font-weight: bold;
        margin-bottom: -2px;
        margin-right: -2px;
        &:last-child {
          margin-bottom: 0;
        }
        &:hover,
        &.active {
          background: white;
          color: inherit;
        }
        &.active {
          border-right-color: white;
        }
        &:before {
          content: "//";
          color: ${C.blue};
          // margin-left: -10px;
          margin-right: 10px;
          font-weight: 900;
          text-shadow: 2px 0 ${C.blue};
        }
      }
      @media (max-width: 720px) {
        min-width: 0;
        width: auto;
        margin-bottom: -2px;
        .tab-btn {
          display: inline-block;
          border-right: 2px solid ${C.gray};
          &.active {
            border-right-color: ${C.gray};
          }
        }
      }
    }
    .tab-content {
      flex-grow: 100;
      background: white;
      border: 2px solid ${C.blue};
      padding: 30px 40px;
      position: relative;

      h3 {
        font-size: 40px;
        margin-bottom: 20px;
      }
      img {
        display: block;
        margin-bottom: 20px;
      }
      .btn {
        @media (min-width: 721px) {
          position: absolute;
          top: 30px;
          right: 40px;
        }
      }
    }
  }
`;

export default class IndexPage extends React.Component {
  constructor() {
    super();
    this.vantaRef = React.createRef();
    this.state = {
      projectsTab: "1",
      validatorsTab: "1",
    };
  }
  openTab(category, tab) {
    const name = category + "Tab";
    if (this.state[name] !== tab) {
      const newState = {};
      newState[name] = tab;
      this.setState(newState);
    }
  }
  componentDidMount() {
    this.effect = VANTA({
      el: this.vantaRef.current,
      backgroundAlpha: 0,
      backgroundColor: C.bg,
      color: C.blueLight2,
      color2: C.blue,
      // color: C.blue,
      points: 10,
      spacing: 15,
    });
  }
  componentWillUnmount() {
    if (this.effect) this.effect.destroy();
  }
  renderTabBtn(category, i, name) {
    const istr = i.toString();
    return (
      <span
        className={
          "tab-btn " + (this.state[category + "Tab"] === istr ? "active" : "")
        }
        onClick={() => this.openTab(category, istr)}
      >
        {" "}
        {name}{" "}
      </span>
    );
  }
  render() {
    return (
      <Layout>
        <Helmet>
          <meta
            key="description"
            name="description"
            content="Polar.io is a blockchain lab. We creates blockchain infrastructure, core decentralized applications, developer tools and decentralized platforms. Polar.io helps chains to scale!"
            data-react-helmet="true"
          />
          <meta
            key="og:url"
            property="og:url"
            content="https://www.polar.io"
            data-react-helmet="true"
          />
          <meta
            key="og:type"
            property="og:type"
            content="article"
            data-react-helmet="true"
          />
          <meta
            key="og:title"
            property="og:title"
            content="Polar.io - Blockchain Lab."
            data-react-helmet="true"
          />
          <meta
            key="og:description"
            property="og:description"
            content="Polar.io is a blockchain lab. We creates blockchain infrastructure, core decentralized applications, developer tools and decentralized platforms. Polar.io helps chains to scale!"
            data-react-helmet="true"
          />
          <meta
            key="og:image"
            property="og:image"
            content={ogImageFinal}
            data-react-helmet="true"
          />
          <meta
            key="twitter:card"
            name="twitter:card"
            content="summary_large_image"
            data-react-helmet="true"
          />
          <meta
            key="twitter:site"
            name="twitter:site"
            content="@eosasia_one"
            data-react-helmet="true"
          />
          <meta
            name="twitter:image"
            content={ogImageFinal}
            data-react-helmet="true"
          ></meta>
        </Helmet>

        <Home>
          <div className="vanta" ref={this.vantaRef}>
            {" "}
          </div>
          <Container>
            <section id="top-section" className="top">
              <div>
                <h1>
                  {" "}
                  A Blockchain Lab<span className="blue">.</span>{" "}
                </h1>
                {/* <h4> Infrastructure, validators, applications </h4> */}
              </div>
            </section>
            <section>
              <h2>
                {" "}
                <img
                  alt="Polar.io logo"
                  src={logo}
                  className="polar-logo"
                />{" "}
                What We Do{" "}
              </h2>
              <div className="boxes">
                <div className="box third">
                  <h5>
                    <span>Infrastructure</span>
                  </h5>
                  <p>
                    {" "}
                    Our systems handle billions of requests, across several of
                    the world's top blockchain networks. We allow blockchains to
                    scale en masse.{" "}
                  </p>
                </div>
                <div className="box third">
                  <h5>
                    <span>Chain Validation</span>
                  </h5>
                  <p>
                    {" "}
                    We help chains scale. We focus on providing secure,
                    efficient and transparent validation services on PoS, DPoS
                    and all PoS liked consensus blockchain.{" "}
                  </p>
                </div>
                <div className="box third">
                  <h5>
                    <span>Investors</span>
                  </h5>
                  <p>
                    Polar incubate and invests in blockchain and distributed
                    ledger technologies. Supporting innovation from the most
                    promising projects.
                  </p>
                </div>
              </div>
            </section>

            <section>
              <h2>
                {" "}
                <i className="fal fa-link" /> Validators{" "}
              </h2>
              <p>We run professional validators and nodes on these chains.</p>
              <div className="tab-flex">
                <div className="tab-btns">
                  {this.renderTabBtn("validators", 1, "EOS")}
                  {this.renderTabBtn("validators", 2, "AVALANCHE")}
                  {this.renderTabBtn("validators", 3, "WAX")}
                  {this.renderTabBtn("validators", 4, "APTOS")}
                  {this.renderTabBtn("validators", 5, "Your Chain?")}
                </div>
                <TabContent activeTab={this.state.validatorsTab}>
                  <TabPane tabId="1">
                    <h3>EOS</h3>
                    <img alt="EOS Banner" src={eos} className="banner border" />
                    <p>
                      {" "}
                      Polar operates a top 21 Block Producer on the EOS network
                      ever since the launch of the Mainnet in June 2018 under
                      the name of EOS Asia.
                      <br />
                      We've worked closely with other BPs to launch EOS and push
                      it into the future.
                    </p>
                    <a className="btn" href="https://www.eos.io">
                      View EOS &rarr;
                    </a>
                  </TabPane>
                  <TabPane tabId="2">
                    <h3>AVALANCHE</h3>
                    <img
                      alt="Avalanche Banner"
                      src={avalanche}
                      className="banner"
                    />
                    <p>
                      Polar operates a node in Avalanche. The goal of Avalanche
                      is to be the best verifiable platform for institutions,
                      enterprises, and governments.
                    </p>
                    <a className="btn" href="https://www.avax.network/">
                      View Avalanche &rarr;
                    </a>
                  </TabPane>
                  <TabPane tabId="3">
                    <h3>WAX</h3>
                    <img
                      alt="WAX Banner"
                      src={waxImage}
                      className="banner border"
                    />
                    <p>
                      WAX is the safest and most convenient way to create, buy,
                      sell, and trade virtual items - to anyone, anywhere in the
                      world. WAX is the world's first blockchain built for
                      global e-commerce. WAX is creating a full suite of
                      blockchain-based tools that allows anyone to trade digital
                      or even physical items instantly and securely, to anyone,
                      anywhere.
                    </p>
                    <a className="btn" href="https://wax.io/">
                      View WAX &rarr;
                    </a>
                  </TabPane>
                  <TabPane tabId="4">
                    <h3>APTOS</h3>
                    <img alt="APTOS Banner" src={aptos} className="banner" />
                    <p>
                      Polar is on the works to be a producer on the APTOS chain.
                      A Layer 1 blockchain that aims to solve reliability,
                      scalability, and usability issues that have plagued
                      existing Layer 1s, whilst possessing security features
                      lacking in EVM-based blockchains. Aptos uses Move, a
                      Rust-based programming language created for Diem, a now
                      abandoned blockchain project by Meta (formerly Facebook).
                    </p>
                    <a className="btn" href="https://aptoslabs.com/">
                      View Aptos &rarr;
                    </a>
                  </TabPane>
                  <TabPane tabId="5">
                    <h3>Your chain?</h3>
                    <p>
                      {" "}
                      Does your chain need validator nodes? We've been trusted{" "}
                      <b>top</b> validator nodes for several chains, and we've
                      successfully scaled to billions of transactions.
                    </p>
                    <br />
                    <a className="btn" href="mailto:cp@eosasia.one">
                      Contact Us
                    </a>
                  </TabPane>
                </TabContent>
              </div>
            </section>

            <section>
              <h2>
                {" "}
                <i className="fal fa-flask" /> Subsidiary Projects{" "}
              </h2>
              <div className="tab-flex">
                <div className="tab-btns">
                  {this.renderTabBtn("projects", 1, "EOSX")}
                  {this.renderTabBtn("projects", 2, "EOS Infra")}
                  {this.renderTabBtn("projects", 3, "EOS Pixels")}
                  {this.renderTabBtn("projects", 4, "EOS Go")}
                </div>
                <TabContent activeTab={this.state.projectsTab}>
                  <TabPane tabId="1">
                    <h3>EOSX</h3>
                    <img
                      alt="EOSX Banner"
                      src={eosx}
                      className="banner border"
                    />
                    <p>
                      An all-in-one EOS scanner & toolkit. Voting, transaction
                      scanner, account scanner, BP scanner, account creation,
                      proxy management, buy/sell RAM, name auction, and more!
                      <br />
                      EOSX is the next evolution of EOSIO chain visualization
                      and the most advanced block explorer ever created!
                    </p>
                    <a className="btn" href="https://www.eosx.io">
                      View EOSX &rarr;
                    </a>
                  </TabPane>
                  <TabPane tabId="2">
                    <h3>EOS Infra</h3>
                    <img
                      alt="EOS Infra Banner"
                      src={eosInfra}
                      className="banner"
                    />
                    <p>
                      Scalable & reliable API access for dApps on EOS. Used by
                      50% of the top DApps in the EOS ecosystem. We've served
                      over <b>25 billion requests</b> through our free service{" "}
                      <b>with 0 downtime</b>! <br />
                      To support all premium features, we have rolled our own
                      dedicated clusters of EOS API nodes around the globe to
                      ensure maximum performance, minimal latency, and
                      consistent configuration.
                    </p>
                    <a className="btn" href="https://www.eosinfra.io">
                      View EOS Infra &rarr;
                    </a>
                  </TabPane>
                  <TabPane tabId="3">
                    <h3>EOS Pixels</h3>
                    <img
                      alt="EOS Pixels banner"
                      src={eosPixels}
                      className="banner"
                    />
                    <p>
                      We created and open sourced the code of what became one of
                      the most popular DApps in EOS:{" "}
                      <a href="https://pixelmaster.io">PixelMaster</a>. This
                      project also inspired the economic model of the latest
                      Block.one Project: <a href="https://voice.com">Voice</a>.
                      <br />
                      EOS Pixels is a live canvas drawing DApp built on EOS,
                      where each pixel changes hands for EOS tokens. After a
                      certain amount of inactivity, the canvas, with all its
                      painted pixels, will be saved for all eternity and a new
                      canvas will be freshly created.
                    </p>
                    <a className="btn" href="https://www.eospixels.io">
                      View EOS Pixels &rarr;
                    </a>
                  </TabPane>
                  <TabPane tabId="4">
                    <h3>EOS Go</h3>
                    <img
                      alt="EOS Go Banner"
                      src={eosGo}
                      className="banner border"
                    />
                    <p>
                      The largest EOS community and the go to place for EOS
                      projects, EOS tokens market cap rankings, EOS news & blog
                      and DApp explorer. EOS Go is also the biggest western
                      media with over 50k members.
                    </p>
                    <a className="btn" href="https://www.eosgo.io">
                      View EOS Go &rarr;
                    </a>
                  </TabPane>
                </TabContent>
              </div>
            </section>

            {/* <section>
            <h2> <i className='fal fa-briefcase'/> Who We Are </h2>
            <p>
              Dozens of years
            </p>
            <br/>
            <br/>
            <a className='btn' href='mailto:hi@polar.io'>Contact Us</a>
          </section> */}

            {/* <section className='team-section'>
            <h2> <i className='far fa-users'/> Team </h2>
            <div className='team-member'>
              <img src='images/dafeng.jpg' alt="Dafeng Guo"></img>
              <div className='team-info'>
                <h3> Dafeng Guo </h3>
                <p className='team-description'>
                  A lifelong technologist with a deep passion for blockchain, Dafeng brings world-class technical capabilities to EOS Asia. As cofounder and CTO of Strikingly, one of the world's top publishing platforms,   Dafeng has architected systems that serve millions of users and billions of pageviews. He was named Alibaba Cloud Tech MVP in 2017. As cofounder of Strikingly, he was accepted into Y Combinator's W13 batch. Dafeng has previously developed trading systems and infrastucture at Morgan Stanley, Goldman Sachs, and Yahoo. He received his BS in Computer Science at CUHK.
                </p>
              </div>
            </div>
            <div className='team-member'>
              <img src='images/joe.jpg' alt="Joseph Fanelli"></img>
              <div className='team-info'>
                <h3> Joseph Fanelli </h3>
                <p className='team-description'>
                  Joseph graduated from TongJi university with a background in Finance with astute skills in Computer Science. Over the years he has cooperated with many large corporations including China’s Tencent QQ Music managing large projects and took the helm of conglomerate NIO Capital’s online presence all while managing multiple companies throughout Asia. He is now the founder of EOS Link, member of EOS Go and of the City of Zion. His goal with the EOS Link project is linking the Chinese community to communicate with overseas communities and on becoming Block Producers.
                </p>
              </div>
            </div>
          </section> */}

            <section>
              <h2>
                {" "}
                <i className="fal fa-briefcase" /> Consulting{" "}
              </h2>
              <p>
                With deep technical expertise in blockchain and distributed
                computing, we are available for one-on-one consulting projects.
              </p>
              <br />
              <br />
              <a className="btn" href="mailto:hi@polar.io">
                Contact Us
              </a>
            </section>
          </Container>
        </Home>
      </Layout>
    );
  }
}

// const IndexPage = ({ data }) => {
//   const posts = data.allMarkdownRemark.edges.filter(
//     (post) =>
//       !post.node.frontmatter.hidden &&
//       post.node.frontmatter.contentType === "blog"
//   );
//   return (
//     <Layout>
//       <Container>
//         {posts.map(({ node: post }) => (
//           <Card style={{ marginBottom: 10 }} key={post.id}>
//             <CardBody>
//               <CardTitle>
//                 <Link to={post.frontmatter.path}>{post.frontmatter.title}</Link>
//               </CardTitle>
//               <CardSubtitle style={{ marginBottom: 10 }}>
//                 {post.frontmatter.date}
//               </CardSubtitle>
//               <CardText>{post.excerpt}</CardText>
//             </CardBody>
//           </Card>
//         ))}
//       </Container>
//     </Layout>
//   );
// };

// export const pageQuery = graphql`
//   query IndexQuery {
//     allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
//       edges {
//         node {
//           excerpt(pruneLength: 400)
//           id
//           frontmatter {
//             title
//             contentType
//             date(formatString: "MMMM DD, YYYY")
//             path
//             hidden
//           }
//         }
//       }
//     }
//   }
// `
